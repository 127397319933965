<template>
    <div
        v-if="this.$route.name == 'home' && isPickupDelivery == '1' && $auth.setting && $auth.setting.product_type && $auth.setting.product_type.id == 2 && $auth.setting.store_selection == 0">
        <div v-if="isItemLoader" class="container container-fluid mt-4">
            <div class="spicy">
                <VueSlickCarousel v-bind="tagsettings">
                    <div v-for="index in 5" :key="index" class="product-card">
                        <div class="card position-relative">
                            <skeleton-loader-vue class="item-img tags-squre" width="100%" height="150px" />
                            <div class="card-body">
                                <skeleton-loader-vue width="80%" height="20px" class="mb-2" />
                                <skeleton-loader-vue width="100%" height="40px" class="mb-2" />
                                <skeleton-loader-vue width="50px" height="20px" />
                            </div>
                            <div class="pgnme-cls btn-top">
                                <div class="tag-item-btn card-body">
                                    <skeleton-loader-vue width="85px" height="30px" />
                                </div>
                            </div>
                        </div>
                    </div>
                </VueSlickCarousel>
            </div>
        </div>
        <div v-if="tag.menu_items.length > 0" class="container container-fluid mt-4" v-for="tag in tagWiseMenu"
            :key="tag.id" style="padding: 20px; background: #f1f1f1; border-radius: 20px;">
            <div class="d-flex">
                <h4 v-if="tag.menu_items.length > 0">{{ tag.tag_name }}</h4>
                <!-- <p class="view-all" @click="viewAllitem(tag.id)">{{ $t('View All') }}</p> -->
            </div>
            <div class="spicy">
                <VueSlickCarousel v-bind="tagsettings" v-if="tag.menu_items.length > 0" class="round-arrow">
                    <div v-for="menu in tag.menu_items" :key="menu.restaurant_menu_item_id" class="product-card"
                        v-if="tag.menu_items && tag.menu_items.length > 0">
                        <div class="card position-relative">
                            <div @click="productModal(menu)">
                                <p class="free-offer" v-if="menu.name_for_promocode">{{ menu.name_for_promocode }}</p>
                                <div class="item-img tags-squre">
                                    <img :src="menu.image" @error="onImgError()" v-if="!imgError && menu.image" />
                                    <img src="../assets//images/default-img.jpg" @error="onImgError()" v-else />
                                </div>

                            </div>
                            <div class="card-body">
                                <div class="w-100">
                                    <div class="veg nveg" v-if="menu.item_type == '0'"><span></span></div>
                                    <div class="non-veg nveg" v-if="menu.item_type == '1'"><span></span></div>
                                    <h6 class="card-title">{{ menu.item_name.length > 15 ? menu.item_name.slice(0, 15) +
                                        '...' : menu.item_name }}</h6>
                                </div>
                                <div>
                                    <p class="mb-0 mt-2 item-descr"> {{ menu.item_description.length > 50 ?
                                        menu.item_description.slice(0, 50) + '...' : menu.item_description }} </p>
                                    <div>
                                        <span class="currency" v-html="$auth.setting.currency"></span>{{
                                            menu.price }}</span>
                                    </div>

                                </div>
                                <!-- <button class="btn btn-danger w-100">Add to Cart</button> -->
                            </div>
                            <div class="d-flex justify-content-between">
                                <!-- <div
                                    v-if="menu && menu.is_item_subscribe == 1 && menu.is_customization == 0 && isPickupDelivery == 1">
                                    <button class="btn px-3 pb-3" @click="addSubscription(menu)">
                                        <img src="../assets/images/sync.png" alt="" class="subscription-icon" />
                                    </button>
                                </div> -->
                                <div class="pgnme-cls mt-auto ms-auto" v-if="pagename != 'qr'">
                                    <div class="tag-item-btn px-3 pb-3 d-flex flex-column align-items-end">
                                        <div v-if="menu.is_sold_out == '0'"
                                            class="sold-de | d-flex flex-row-reverse justify-content-between align-items-center">
                                            <div v-if="menu.is_customization >= '1'" class="p-0">
                                                <button class="add-to-cart btn text-center" data-bs-toggle="modal"
                                                    data-bs-target="#item-popup" style="width: 85px"
                                                    v-if="checkInCart(menu.item_id)" @click="productModal(menu)">{{
                                                        $t('Add')
                                                    }}</button>

                                                <div v-else class="quantityInc 1">
                                                    <div class="number d-flex align-items-center">
                                                        <span
                                                            class="minus d-flex justify-content-center align-items-center"
                                                            @click="minusItem(menu)">-</span>
                                                        <input type="text"
                                                            :value="cart.reduce((a, c) => (a + (c.item_id == menu.item_id ? c.quantity : 0)), 0)"
                                                            readonly />
                                                        <span
                                                            class="plus d-flex justify-content-center align-items-center"
                                                            @click="plusItem(menu)">+</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else class=" p-0">
                                                <button v-if="menu.is_sold_out == '0' && checkInCart(menu.item_id)"
                                                    data-bs-toggle="modal" class="add-to-cart btn" style="width: 85px"
                                                    @click="addProductToCart(menu)">{{
                                                        $t('Add')
                                                    }}</button>
                                                <div v-else>
                                                    <div class="quantityInc 2">
                                                        <div class="number d-flex align-items-center">
                                                            <span
                                                                class="minus d-flex justify-content-center align-items-center"
                                                                @click="minusItem(menu)">-</span>
                                                            <input type="text"
                                                                :value="cart.reduce((a, c) => (a + (c.item_id == menu.item_id ? c.quantity : 0)), 0)"
                                                                readonly />
                                                            <span
                                                                class="plus d-flex justify-content-center align-items-center"
                                                                @click="plusItem(menu)">+</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <h5 v-if="menu.is_customization" class="tag-customize tagcust-nw">{{
                                            $t('Customisable') }}</h5>
                                        <div v-else class="sold-bt">
                                            <button v-if="menu.is_sold_out != '0'"
                                                class="sold add-to-cart btn btn-outline-warning btn-sm">{{ $t('Sold
                                                Out')}}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </VueSlickCarousel>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import VueSkeletonLoader from 'skeleton-loader-vue';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import AlertModal from '../components/AlertModal.vue';
import ProductDetails from "@/components/ProductDetails.vue";
import Product from "@/components/Product.vue";


export default {
    name: "SingleTagwiseMenus",
    components: {
        'skeleton-loader-vue': VueSkeletonLoader,
        VueSlickCarousel,
        AlertModal,
        ProductDetails,
        Product,
    },
    data() {
        return {
            // props: ["item", "menuId", "currencys", "restaurantID", "restaurant_on_off", "pagename", "slug", "itemlayout", "restautantDetails"],
            pagename: 'home',
            resstoId: "",
            restaurant_on_off: 1,
            tagWiseMenu: [],
            imgError: "",
            noImgClass: '',
            tempProduct: {},
            isAlert: false,
            AlertData: {},
            isItemLoader: true,
            singleVendorRestaurantID: 0,
            filter: {
                lat: "",
                lng: "",
                city: "",
                state: "",
                is_pickup_delivery: "",
            },
            slidesToShow: 3,

            tagsettings: {
                "dots": false,
                "arrows": true,
                "infinite": false,
                "speed": 500,
                "slidesToShow": 5,
                "slidesToScroll": 1,
                "initialSlide": 0,
                // "adaptiveHeight": true,
                "responsive": [
                    {
                        "breakpoint": 1399,
                        "settings": {
                            "slidesToShow": 4,
                            "slidesToScroll": 1,
                            "infinite": true,
                            "dots": false,
                            "arrows": true,
                        },
                    },
                    {
                        "breakpoint": 1024,
                        "settings": {
                            "slidesToShow": 3,
                            "slidesToScroll": 1,
                            "infinite": true,
                            "dots": false,
                            "arrows": true,
                        },
                    },
                    {
                        "breakpoint": 991,
                        "settings": {
                            "slidesToShow": 2,
                            "slidesToScroll": 1,

                        },
                    },
                    {
                        "breakpoint": 480,
                        "settings": {
                            "slidesToShow": 1.3,
                            "slidesToScroll": 1,
                            "dots": true,
                            "arrows": false,
                            "infinite": false,
                        }
                    }
                ]
            },
        }
    },
    computed: {
        ...mapGetters("order", ["isPickupDelivery"]),
        ...mapGetters("product", ["products", "cart"]),
    },
    mounted() {
        this.filter.lat = ((localStorage.getItem("latitude") != '' && localStorage.getItem("latitude") != null) ? parseFloat(localStorage.getItem("latitude")) : '');
        this.filter.lng = ((localStorage.getItem("longitude") != '' && localStorage.getItem("longitude") != null) ? parseFloat(localStorage.getItem("longitude")) : '');
        this.singleVendorRestaurantID = this.base64_decode(localStorage.getItem("restaurantId"));
        // if (localStorage.getItem("restaurantId") == null) {
        //     this.singleVendorRestaurantID = this.$auth.setting.restaurant_id;
        // } else {
        //     this.singleVendorRestaurantID = this.base64_decode(localStorage.getItem("restaurantId"));
        // }
        this.getTagWiseRestaurantMenus()

    },
    methods: {
        ...mapActions("product", ["addProductToCart", "removeProductFromCart", "addTOSubscription"]),
        ...mapActions("restaurant", ["getTagWiseRestaurantItem"]),
        ...mapMutations("product", ["clearCartItems"]),


        viewAllitem() {
            this.$router.push({ name: "tag-wise-menu" });
        },

        addSubscription(product) {
            localStorage.removeItem('subscriptionModal_data')
            localStorage.removeItem('subscription_end_date')
            localStorage.removeItem('subscribedType')
            localStorage.removeItem('subscription_cart')
            localStorage.setItem('freeCode', JSON.stringify(this.item))
            if (product.item_id) {
                localStorage.setItem('itemId', product.item_id);
            }
            // localStorage.removeItem('subscriptionModal_data')
            // productDetailsModal
            // $("#productDetailsModal").modal("hide");
            let itemTax = 0;
            let savemrp = 0;
            if (product.item_tax != "0") {
                itemTax = product.price - product.price / (1 + product.item_tax / 100);
            }

            if (product.price < product.mrp) {
                savemrp = product.mrp - product.price;
            }

            var addToSubscriptionData = {
                vendor_id: this.$auth.getVendorId(),
                restaurant_id: product.restaurant_id,
                item_id: product.item_id,
                item_name: product.item_name,
                // price: product.price,
                price: product.price,
                quantity: 1,
                itemTotalQuantity: product.quantity,
                selected_type: [],
                selectCustoizeID: [],
                selectCustoizePrice: [],
                item_type: product.item_type,
                item_price: product.price,
                image: product.image,
                saveprice: savemrp,
                item_tax: itemTax,
                mrp: product.mrp,
                packaging_charge: product.item_packaging_charge,
            };

            // addSubscription
            this.addTOSubscription(addToSubscriptionData);
            this.$router.push({ name: "review-subscription" });
        },


        plusItem(product) {
            const cartItemIndex = this.cart.findIndex(item => parseInt(item.item_id) === parseInt(product.item_id));
            var itemTotalQuantity = this.cart[cartItemIndex].quantity;
            let cartItem = {
                'cartItemIndex': cartItemIndex,
                'product': product
            };
            if (itemTotalQuantity < product.quantity) {
                this.$store.commit("product/IncrementItemQuantity", cartItem);
            } else {
                alert(this.$t("Maximum items reached"));
            }
        },



        minusItem(product) {
            const cartItemIndex = this.cart.findIndex(item => parseInt(item.item_id) === parseInt(product.item_id));
            let totalSmCartID = [];
            this.cart.forEach(item => {
                if (item.item_id === product.item_id) {
                    totalSmCartID.push(item.item_id);
                }
            });
            if (totalSmCartID.length > 1) {
                alert(this.$t("Please Remove Item From Cart!"));
            } else {
                var itemQuantity = this.cart[cartItemIndex].quantity;
                if (itemQuantity == '1' || itemQuantity == '0') {
                    this.deleteProduct(cartItemIndex);
                } else {
                    this.$store.commit("product/decrementProductInventary", cartItemIndex);
                }
            }
            return false;
        },

        deleteProduct(index) {
            this.$store.dispatch("product/removeProductFromCart", index)
        },

        onImgError() {
            this.imgError = true;
            this.noImgClass = 'noImgClass';
        },
        onModalCloseFun(value) {
            this.isAlert = false;
            if (value == 'yes') {
                this.$store.commit("product/clearCartItems");
                this.addProductCart(this.tempProduct);
            }
        },

        addProductToCart(product) {
            this.resstoId = this.base64_decode(localStorage.getItem("restaurantId"));
            var checkRestarant = this.cart.find((ele) => (ele.restaurant_id == this.resstoId));
            if (this.cart.length) {
                if (checkRestarant) {
                    this.addProductCart(product);
                } else {
                    this.AlertData = {
                        displayMessage: this.$t('Your cart contain items from another ') + this.$t(this.$auth && this.$auth.getRestaurantName()) + this.$t(' Do you wish to clear cart and start a new order here?'),
                        isSetClass: 'prdct-modal-cls'
                    };
                    this.isAlert = true;
                    this.tempProduct = product;
                    window.$("#AlertModalPlaced").modal("show");
                    //window.$(this.$refs.AlertModal).modal();
                }
            } else {
                this.addProductCart(product);
            }
        },

        addProductCart(product) {
            let itemTax = 0;
            let savemrp = 0;
            if (product.item_tax != '0') {
                itemTax = (product.price - (product.price / (1 + (product.item_tax / 100))));
            }

            if (product.price < product.mrp) {
                savemrp = product.mrp - product.price;
            }
            var AddToCartItemArray = {
                vendor_id: this.$auth.getVendorId(),
                restaurant_id: product.restaurant_id,
                item_id: product.item_id,
                item_name: product.item_name,
                price: product.price,
                quantity: 1,
                itemTotalQuantity: product.quantity,
                selected_type: [],
                selectCustoizeID: [],
                selectCustoizePrice: [],
                item_type: product.item_type,
                item_price: product.price,
                image: product.image,
                saveprice: savemrp,
                item_tax: itemTax,
                mrp: product.mrp,
                packaging_charge: product.item_packaging_charge
            };
            this.$store.commit("restaurant/getRestaurantBySlug", this.restautantDetails);
            this.$store.dispatch("product/addProductToCart", AddToCartItemArray);
        },

        checkInCart(itemId) {
            var checkItem = this.cart.find((ele) => (ele.item_id == itemId));
            if (checkItem) {
                return false;
            } else {
                return true;
            }
        },


        getTagWiseRestaurantMenus(restid) {
            this.isItemLoader = true;
            this.resstoId = localStorage.getItem("restaurantID");
            this.getTagWiseRestaurantItem({
                vendor_id: this.$auth.getVendorId(),
                is_langauge: this.$store.state.lang.locale,
                skip: 0,
                latitude: this.filter.lat,
                longitude: this.filter.lng,
                restaurant_id: this.singleVendorRestaurantID,
                total_record: 10,
            }).then((data) => {
                this.isItemLoader = false;
                if (data.code == 200) {
                    this.tagWiseMenu = data.Result;
                }
            })
        },

        productModal(item) {
            item.restaurant_on_off = this.restaurant_on_off;
            item.restaurant_id = this.$auth.setting.restaurant_id;
            // item.restaurant_id = this.restaurantID;
            this.$emit("openProductDetailModal", item);
        },
    }
}
</script>

<style>
.product-card {
    padding: 10px;
}

.card {
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.card:hover {
    transform: scale(1.05);
}



.price {
    font-weight: bold;
    color: #333;
}

.tags-squre img {
    width: 100%;
    object-fit: contain;
    aspect-ratio: 3/1.6;
    object-position: center;
    border-radius: 10px;
}

.icus button {
    widows: 100%;
}

.tag-item-btn {
    text-align: end;
}


.spicy .card {
    overflow: hidden;
    height: 100%;
    min-height: 325px;
}

.spicy .card img {
    transition: all 0.5s;
}

.spicy .item-img {
    overflow: hidden;
    margin: 10px;
    border-radius: 10px;
}

.spicy .card:hover {
    transform: scale(1);
}

.spicy .card:hover img {
    transform: scale(1.05);
}

.spicy .free-offer {
    left: -1px;
    top: -1px;
}

.spicy .sold {
    background: var(--theme-secondary);
}

.spicy .btn:hover {
    background: rgb(220, 53, 69) !important;
    color: white;
}

.spicy .sold {
    color: var(--theme-font-primary);
    background: var(--theme-button-secondary-color);
    border-color: transparent;
    pointer-events: none;
}

.spicy .tag-customize {
    margin-bottom: 0 !important;
    margin-top: 5px;
    color: var(--theme-font-primary) !important;
    font-size: 10px !important;
    text-align: end !important;
    /* font-weight: 700;*/
}

.add-to-cart {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    top: 0;
    bottom: 0;
    right: 0;
    cursor: pointer;
    z-index: 1;
    left: 132px;
    background: rgba(var(--theme-primary-button-color));
    border: 1px solid rgba(var(--theme-primary));
    color: var(--theme-button-text-primary-color);
    border-radius: 2rem;
    text-transform: uppercase;
    font-weight: 500;
    width: 85px;
    height: 30px;
}

.view-all {
    margin-left: auto;
    cursor: pointer;
}


@media(max-width: 580px) {
    .spicy .card {
        min-height: 370px;
    }

    .spicy .slick-dots {
        bottom: 0;
        margin: 0;
    }
}
</style>